<template>
  <div class="container">
    <div class="mx-auto mb-3 img-container">
      <div class="row  d-flex justify-content-center">
        <div class="col-lg-8 col-md-12">
            <div class="text-center">
              <img class="img-fluid mb-3" src="../../assets/img/decorations/our_top_offer_for_all_patients.svg" alt="Fleckdekoration">
            </div>
        </div>
      </div>
    </div>
    <div class="text-primary mt-1 fs-4 text-justify">
        <p>
          Privatpatienten können bei uns zwischen den Paketen
          <span class="text-bold">Friends</span> und <span class="text-bold">Komfort</span> wählen.
          Beide bieten dir eine hochwertige und individuelle Betreuung.
          Nach Auswahl des passenden Pakets wird eine Honorarvereinbarung getroffen,
          und sobald du diese akzeptierst, kann deine Therapie beginnen.
        </p>
    </div>
    <div class="row">
        <div class="col-lg-6 text-center mb-3">
          <div class="packet-box">
              <div class="header-box">
                  <div class="text-center time-box p-2">
                      <div class="text-primary h5">
                        <u>Therapiezeit modular wählbar</u>
                      </div>
                      <div class="text-white h5">
                        *Kompakt | Rundum | Vertieft
                      </div>
                  </div>
                  <div>
                      <div class="text-left text-tertiary h1">
                        Friends
                      </div>
                      <div class="text-left text-white h3">
                        Satz 1,4
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line1">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>"Der Grüne Faden"</u>
                      </div>
                      <div class="fs-5">
                          Ganzheitlicher Therapieansatz mit klar strukturierten Schritten:
                          <span class="text-bold">Anamnese, Befund, Diagnostik, Therapieplan, Ergonomieberatung und Evaluation.</span>
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line2">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Service "Die Rote Karte"</u>
                      </div>
                      <div class="fs-5">
                        Unterstützung bei Problemen mit der <span class="text-bold">Kostenerstattung</span> durch die Versicherung.*
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line3">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Therapeutische Expertise</u>
                      </div>
                      <div class="fs-5">
                        <span class="text-bold">Hochqualifizierte Experten</span>-Therapeut:inen mit neuen Ansätzen und spezifischen Fortbildungen
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line4">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Ergänzendes Heilmittel</u>
                      </div>
                      <div class="fs-5">
                        Wenn nicht extra ärztlich verordnet, kann es <span class="text-bold">zusätzlich</span> gebucht werden. (Details im Dienstleistung Informationsblatt)
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-left align-items-center d-flex" id="line5">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>VO-Service</u>
                      </div>
                      <div class="fs-5">
                        Ab der dritten <span class="text-bold">Verordnung</span> kümmern wir uns um alle weiteren
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-left align-items-center d-flex" id="line6">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Terminbuchung</u>
                      </div>
                      <div class="fs-5">
                        Fest <span class="text-bold">reservierter</span> Therapieplatz
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line7">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Nutzung des Kraftraums</u>
                      </div>
                      <div class="fs-5">
                        Ergänzende Therapieplan mit max. 4 Übungen <span class="text-bold">inklusive</span>
                      </div>
                  </div>
              </div>
              <div class="h4 text-left ">
                <a href="/Preispakete_Übersicht_Angebot.pdf" target="_blank" class="lime-color" style="text-decoration: none;">
                  Dienstleistung-<br>informationsblatt<br>
                  (PDF download)
                </a>
              </div>
              <div class="footer-box" id="footer">
                <div class="h5 text-primary">
                  <u>Erfülle eine der Bedingungen:</u>
                </div>
                <div class="fs6 text-left">
                  <ul>
                    <li>Schnell und unkompliziert zahlen SEPA Lastschrift (Rechnung direkt begleichen)</li>
                    <li>Treue-Bonus: Bereits zum dritten Mal in Folge eine Verordnung einreichen</li>
                    <li>Bewohner einer sozialen Einrichtung (z.B. Pflegeheim)</li>
                  </ul>
                </div>
              </div>
          </div>
        </div>
        <div class="col-lg-6 mx-auto text-center mb-3">
          <div class="packet-box">
              <div class="header-box">
                <div class="text-center time-box p-2">
                      <div class="text-primary h5">
                        <u>Therapiezeit modular wählbar</u>
                      </div>
                      <div class="text-white h5">
                        *Kompakt | Rundum | Vertieft
                      </div>
                  </div>
                  <div>
                      <div class="text-left text-tertiary h1">
                        Komfort
                      </div>
                      <div class="text-left text-white h3">
                        Satz 1,8
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line1">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>"Der Grüne Faden"</u>
                      </div>
                      <div class="fs-5">
                          Ganzheitlicher Therapieansatz mit klar strukturierten Schritten:
                          <span class="text-bold">Anamnese, Befund, Diagnostik, Therapieplan, Ergonomieberatung und Evaluation.</span>
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line2">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Service "Die Rote Karte"</u>
                      </div>
                      <div class="fs-5">
                        Unterstützung bei Problemen mit der <span class="text-bold">Kostenerstattung</span> durch die Versicherung.*
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line3">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/plus.png" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Therapeutische Expertise</u>
                      </div>
                      <div class="fs-5">
                        <span class="text-bold">Hochqualifizierte Experten</span>-Therapeut:inen mit neuen Ansätzen und spezifischen Fortbildungen
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-center align-items-center d-flex" id="line4">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/minus.svg" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Ergänzendes Heilmittel</u>
                      </div>
                      <div class="fs-5">
                        Wenn nicht extra ärztlich verordnet, kann es <span class="text-bold">teils zusätzlich</span> gebucht werden. (Details im Dienstleistung Informationsblatt)
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-left align-items-center d-flex" id="line5">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/minus.svg" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>VO-Service</u>
                      </div>
                      <div class="fs-5">
                        Ab der dritten <span class="text-bold">Verordnung</span> kümmern wir uns um alle weiteren
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-left align-items-center d-flex" id="line6">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/minus.svg" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Terminbuchung</u>
                      </div>
                      <div class="fs-5">
                        <span class="text-bold">Flexible</span> Standard-Terminvergabe
                      </div>
                  </div>
              </div>
              <div class="text-box-offer text-left justify-content-left align-items-center d-flex" id="line7">
                  <div class="img-box-offer mr-2">
                    <img class="img-fluid" src="../../assets/img/decorations/minus.svg" alt="Fleckdekoration">
                  </div>
                  <div>
                      <div class="fs-5 text-white">
                          <u>Nutzung des Kraftraums</u>
                      </div>
                      <div class="fs-5">
                        Individueller therapieplan <span class="text-bold">extra buchbar</span>
                      </div>
                  </div>
              </div>
              <div class="h4 text-left">
                <a href="/Preispakete_Übersicht_Angebot.pdf" target="_blank" class="lime-color" style="text-decoration: none;">
                  Dienstleistung-<br>informationsblatt<br>
                  (PDF download)
                </a>
              </div>
              <div class="footer-box" id="footer" >
                <div class="h5 text-primary">
                  <u>Unkompliziert:</u>
                </div>
                <div class="fs-6 text-center">
                  Einfach Termin(e) buchen oder eine Verordnung vorlegen und profitiere von unseren professionellen Leistungen.
                </div>
              </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="text-center col-md-12 col-lg-4">
            <AppointmentReservation />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import AppointmentReservation from '../Ui/AppointmentReservation.vue';

export default {
    name: 'FeelDifference',
    components: {
        AppointmentReservation
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};

</script>

<style>
.lime-color {
  color: var(--bs-text);
}

.text-bold {
  font-weight: bold;
}

.text-stroked {
  font-family: 'HP Simplified';
  color: white;
  font-size: 45px;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-text-stroke: 2px #08425e;
}

@media screen and (max-width: 1199px){
    .text-stroked
    {
        font-size: 2.7vw;
        -webkit-text-stroke: 0.1vw #08425e;
    }
}

@media screen and (max-width: 991px) {
    .text-stroked {
        font-size: 4.3vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 767px) {
    .text-stroked {
        font-size: 4vw;
        -webkit-text-stroke: 0.2vw #08425e;
    }
}

@media screen and (max-width: 576px) {
    .text-stroked {
        font-size: 5.7vw;
        -webkit-text-stroke: 0.3vw #08425e;
    }
}

.img-container {
  position: relative;
}

.packet-box {
  background-color: #829caa;
  border: 5px solid #b0c34a;
  border-radius: 50px;
  padding: 20px;
  margin: 20px 0;
}

.header-box {
  position: relative;
  background-color: #07425e;
  border: 5px solid #b0c34a;
  border-radius: 30px;
  padding: 20px;
  margin: 20px 0;
}

.text-box-offer {
  border-bottom: 5px solid #b0c34a;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  background-color: #829caa;
  padding: 20px;
  margin: 20px 0;
}

@media screen and (min-width: 1400px) {
  #line7 {
    height: 110px;
  }

  #footer {
    height: 210px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  #line3 {
    height: 130px;
  }

  #line4 {
    height: 170px;
  }

  #line7 {
    height: 130px;
  }

  #footer {
    height: 230px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  #line3 {
    height: 170px;
  }
  #line4 {
    height: 210px;
  }

  #line6 {
    height: 130px;
  }

  #footer {
    height: 260px;
  }

}

.footer-box {
  position: relative;
  background-color: #ffffff;
  border: 5px solid #b0c34a;
  border-radius: 30px;
  padding: 20px;
  margin: 20px 0;
}

.time-box {
  background-color: #b0c34a;
  border-radius: 0 15px 0 30px;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (max-width: 576px) {
  .time-box {
    border-radius: 0 30px 0 30px;
    position: relative;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 577px) and (max-width: 1399px) {
  .time-box {
    border-radius: 0 30px 0 30px;
    position: relative;
    margin-bottom: 15px;
  }
}

.img-box-offer {
  min-width: 25px;
  max-width: 25px;
}

.reserve-box {
  border-radius: 30px;
  border: 5px solid #b0c34a;
  padding: 30px;
  margin: 20px 0;
  cursor: pointer;
}

.reserve-box:hover {
  border: 5px solid #07425e;
  background-color: #b0c34a !important;
}
</style>
